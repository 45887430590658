import './styles.scss'
import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

import ProductSlider from '../../product/ProductSlider'
import type { SearchResult } from '../../sections/ProductGalleryV2/types'
import TrainPartnes from './TrainPartners'
import DicasTreino from './DicasTreino'
import CompleteTrains from './CompleteTrains'
import TrainInHouse from './TrainInHouse'
import * as storeConfig from '../../../../store.config'

const { decathlonSearch } = storeConfig

const TreinoEmCasaComponent = () => {
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null)

  const getShelfData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${decathlonSearch}/product/collection?engine=vtex&sc=3&sort=descsold&term=411`
      )

      setSearchResult(data)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    getShelfData()
  }, [getShelfData])

  return (
    <main className="lp-treino-em-casa">
      <img
        src="https://decathlonpro.vteximg.com.br/arquivos/banner-desktop-treino-em-casa.png"
        alt="Treino em Casa | Decathlon"
      />
      <TrainPartnes />
      <DicasTreino />
      <CompleteTrains />
      <TrainInHouse />
      {searchResult && (
        <ProductSlider
          title="PRODUTOS PARA AJUDAR NO SEU TREINO"
          products={searchResult?.products}
          linkButton="https://www.decathlon.com.br/pesquisa/?q=treino-casa&pageType=hotsite"
          descriptionButton="Ver os produtos"
        />
      )}
    </main>
  )
}

export default TreinoEmCasaComponent
