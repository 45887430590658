import Seo from 'src/components/seo/Seo'

import TreinoEmCasaComponent from '../components/Comercial/TreinoEmCasa'

function Page() {
  return (
    <>
      <Seo title="Treino em Casa | Decathlon" />
      <TreinoEmCasaComponent />
    </>
  )
}

export default Page
